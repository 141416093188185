<template>
  <div class="container is-fluid">
    <div class="panel custom-panel">
      <div class="panel-block text-container">
        <div class="not-found-text">
          <h1 class="title is-1">404</h1>
          <h2 class="subtitle is-2">Página não encontrada</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PaginaInexistente',
};
</script>

<style lang="scss" scoped>
.custom-panel {
  background-color: rgb(216, 216, 216);
}

.not-found-text {
  text-align: center;
  margin-top: 10%;
  margin-bottom: 10%;
}

.text-container {
  margin: 2rem 0;
  justify-content: center !important;
}
</style>
